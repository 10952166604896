<router-outlet *ngIf="isLoggedIn"></router-outlet>


<div *ngIf="!isLoggedIn" class="container" style="display: flex;justify-content: center;align-items: center;height: 100%;">
    <nz-card>
        <div nz-row [nzGutter]="[18,18]">
            <div nz-col nzSpan="24">
                <strong>Email</strong>
                <input nz-input [(ngModel)]="email" />
            </div>
            <div nz-col nzSpan="24">
                <strong>Password</strong>
                <input nz-input [(ngModel)]="password" type="password"/>
            </div>
            <div nz-col nzSpan="24">
                <button nz-button nzType="primary" (click)="loginFn()">Login</button>
            </div>
        </div>
    </nz-card>
</div>
