<div class="header">
    <div class="logo logo-dark">
        <a href="" style="font-size: 24px;font-weight: 600;color: #393939;position: relative;top: 17px;">
            Artist MRK
        </a>
    </div>
    <div class="logo logo-white">
        <a href="" style="font-size: 24px;font-weight: 600;color: #393939;position: relative;top: 17px;">
            Artist MRK
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
            <li>
                <!-- <a (click)="searchToggle()">
                    <i nz-icon nzType="search" theme="outline"></i>
                </a> -->
                <nz-drawer [nzWidth]="380" nzTitle="Search" [nzVisible]="searchVisible" nzPlacement="left" (nzOnClose)="searchToggle()">
                    <app-search></app-search>
                </nz-drawer>
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <!-- <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="popNotification" [nzPlacement]="'bottomRight'">
                    <nz-badge nzDot>
                        <i nz-icon nzType="bell" theme="outline"></i>
                    </nz-badge>
                </a> -->
                <nz-dropdown-menu #popNotification="nzDropdownMenu">
                    <div nz-menu class="pop-notification">
                        <div class="p-v-15 p-h-25 border-bottom d-flex justify-content-between align-items-center">
                            <p class="text-dark font-weight-semibold m-b-0">
                                <i nz-icon nzType="bell" theme="outline"></i>
                                <span class="m-l-10">Notification</span>
                            </p>
                            <a nz-button nzType="link" nzSize="small" class="p-v-5" [routerLink]="''">
                                <small>View All</small>
                            </a>
                        </div>
                        <perfect-scrollbar class="overflow-y-auto" style="max-height: 300px">
                            <nz-list class="ant-list-item-links" [nzDataSource]="notificationList" [nzRenderItem]="item" [nzItemLayout]="'horizontal'">
                                <ng-template #item let-item>
                                    <nz-list-item>
                                        <a [routerLink]="''">
                                            <div class="d-flex">
                                                <nz-avatar [nzIcon]="item.icon" [ngClass]="item.color"></nz-avatar>
                                                <div class="m-l-15">
                                                    <p class="m-b-0 text-dark">{{item.title}}</p>
                                                    <p class="m-b-0"><small>{{item.time}} ago</small></p>
                                                </div>
                                            </div>
                                        </a>
                                    </nz-list-item>
                                </ng-template>
                            </nz-list>
                        </perfect-scrollbar>
                    </div>
                </nz-dropdown-menu>
            </li>
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar [nzIcon]="'user'" nzSrc="assets/images/avatars/thumb-3.jpg"></nz-avatar>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom">
                            <div class="d-flex m-r-50">
                                <nz-avatar nzSize="large" nzSrc="assets/images/avatars/thumb-3.jpg"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">Mike Ross</p>
                                    <p class="m-b-0 opacity-07">Admin</p>
                                </div>
                            </div>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                                    <span class="m-l-10">Orders</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="project" theme="outline"></i>
                                    <span class="m-l-10">Products</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                        <li nz-menu-item>
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                                    <span class="m-l-10">Logout</span>
                                </div>
                                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
            <li>
                <!-- <a (click)="quickViewToggle()">
                    <i nz-icon nzType="appstore" theme="outline"></i>
                </a> -->
                <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible" nzPlacement="right" (nzOnClose)="quickViewToggle()">
                    <app-quick-view></app-quick-view>
                </nz-drawer>
            </li>
        </ul>
    </div>
</div>    