import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
    {
        path: '/dashboard/default',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: [

        ]
    },
    {
        path: '/apps/e-commerce/products-list',
        title: 'Pictures',
        iconType: 'nzIcon',
        icon: 'shop',
        iconTheme: 'outline',
        submenu: []
    },
    {
        path: '/apps/e-commerce/series-list',
        title: 'Series',
        iconType: 'nzIcon',
        icon: 'folder-view',
        iconTheme: 'outline',
        submenu: []
    },
    {
        path: '/apps/e-commerce/orders-list',
        title: 'Orders',
        iconType: 'nzIcon',
        icon: 'shopping',
        iconTheme: 'outline',
        submenu: []
    }
]