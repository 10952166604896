import { Component, OnInit } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import axios from 'axios';
import {Router} from "@angular/router"

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    name = '';
    username = '';
    email = '';
    password_confirmation = '';
    password = '';
    page = 1;
    remember_password=false;
    showForgerPassword = false;
    isLoggedIn = false;

    constructor(private message: NzMessageService, private router: Router) { }

    createSuccessMessage(msg: string): void {
        this.message.create('success', msg);
      }
    
    createErrorMessage(msg: string): void {
        this.message.create('error', msg);
    }

    ngOnInit () {

        if(localStorage.getItem("token")) {
            this.isLoggedIn = true;
        }

    }

    loginFn() {
        var url = 'https://dashboard-meenakamal.inkapps.io/api/login';
    
        var data = {
          "email": this.email,	
          "password": this.password,	
        };
    
        axios.post(url, data, {}).then(response => {

            if(response.data.user.role=="admin") {
                axios.defaults.headers.common['Authorization'] = 'Bearer '+ response.data.token;
                this.createSuccessMessage(`Logged In Successfully!`);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("email", response.data.user.email);
                localStorage.setItem("name", response.data.user.name);
                localStorage.setItem("id", response.data.user.id);
                localStorage.setItem("role", response.data.user.role);
                localStorage.setItem("status", response.data.user.status);
                localStorage.setItem("username", response.data.user.username);

                this.isLoggedIn = true;
            } else {
                this.createErrorMessage("Unauthorized!");
            }
          
        })
        .catch(error => {
          if(error.response) {
            if(error.response.data) {
              if(error.response.data.error) {
                this.createErrorMessage(error.response.data.error);
              } else {
                this.createErrorMessage("Something went wrong!");
              }
            } else {
              this.createErrorMessage("Something went wrong!");
            }
          } else {
            this.createErrorMessage("Something went wrong!");
          }
        });
      }

}
